const jQuery = require("jquery");

import "bootstrap";

import "./sass/base.sass";

// Sticky responsive header
window.onscroll = function () {
    changeHeaderClass();
};

function changeHeaderClass() {
    if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
    ) {
        document.getElementsByTagName("header")[0].classList.add("is-scrolled");
    } else {
        document
            .getElementsByTagName("header")[0]
            .classList.remove("is-scrolled");
    }
}

// Equalize slider heights

function equalizeSlideHeights() {
    jQuery(".carousel").each(function () {
        const items = jQuery(".carousel-item", this);
        items.css("min-height", 0);
        const maxItemHeight = Math.max.apply(
            null,
            items
                .map(function () {
                    return jQuery(this).outerHeight();
                })
                .get()
        );
        items.css("min-height", maxItemHeight + "px");
    });
}

jQuery(window).on("load resize orientationchange", equalizeSlideHeights);

// Equalize seminar list heights

function equalizeSeminarHeights() {
    jQuery(".seminar-list").each(function () {
        const items = jQuery(".title", this);
        // items.css("min-height", 0);
        const maxItemHeight = Math.max.apply(
            null,
            items
                .map(function () {
                    return jQuery(this).height();
                })
                .get()
        );
        // items.css("min-height", maxItemHeight + "px");
        items.css("flex-basis", maxItemHeight + "px");
    });
}

jQuery(window).on("load resize orientationchange", equalizeSeminarHeights);
